<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-row>
          <b-col v-if="userInfo.roleName === 'OWNER'">
            <label>Master</label>
            <b-select
              v-model="selectedMasterId"
              placeholder="กรุณาเลือก Master"
            >
              <b-select-option value="">
                เลือกทุก master
              </b-select-option>
              <b-select-option
                v-for="item in masters"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </b-select-option>
            </b-select>
          </b-col>
          <b-col v-if="userInfo.roleName !== 'AGENT'">
            <label>Agent</label>
            <b-select v-model="selectedAgentId">
              <b-select-option
                value=""
                disabled
              >
                กรุณาเลือก Agent
              </b-select-option>
              <b-select-option value="">
                เลือกทุก agent
              </b-select-option>
              <b-select-option
                v-for="item in agents"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </b-select-option>
            </b-select>
          </b-col>
          <b-col>
            <label>{{ $t('fields.game') }}</label>
            <b-select
              v-if="!isLoading"
              v-model="filterGame"
            >
              <b-form-select-option
                v-for="(opt, key) in gameOptions"
                :key="key"
                :value="opt.id"
              >
                {{ opt.name }}
              </b-form-select-option>
            </b-select>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="text-right">
        <b-form-group class="d-flex align-items-end justify-content-end">
          <br>
          <day-picker @update="(e) => (selectedDate = e)" />
        </b-form-group>
      </b-col>
    </b-row>
    <component
      :is="selectedTableComponent"
      :game-slug="filterGame"
      :selected-date="selectedDate"
      :selected-master-id="selectedMasterId"
      :selected-agent-id="selectedAgentId"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Games history',
  },
  components: {
    ProviderGamesHistory: () => import('./components/ProviderGameHistory.vue'),
  },
  data() {
    return {
      selectedGameType: 1,
      filterGame: 1,
      selectedDate: '',
      isLoading: true,
      selectedMasterId: null,
      selectedAgentId: null,
      gameTypes: [
        {
          label: 'สล็อต',
          value: 1,
        },
        {
          label: 'ยิงปลา',
          value: 2,
        },
        {
          label: 'คาสิโน',
          value: 3,
        },
        {
          label: 'กีฬา',
          value: 4,
        },
        {
          label: 'กราฟ',
          value: 5,
        },
      ],
    }
  },

  computed: {
    ...mapGetters([
      'miniGames',
      'providerGames',
      'lottoGames',
      'isMaster',
      'isOwner',
    ]),
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    isOwnerOrMaster() {
      return this.isOwner || this.isMaster
    },
    filteredGameTypes() {
      return this.gameTypes || []
    },
    selectedTableComponent() {
      switch (this.selectedGameType) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 'ProviderGamesHistory'
        case 'MINI_GAME':
          return 'MiniGamesHistory'
        case 'LOTTO':
          return 'LottoGamesHistory'
        default:
          return 'ProviderGamesHistory'
      }
    },
    gameOptions() {
      switch (this.selectedGameType) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return this.providerGames
        case 'MINI_GAME':
          return this.miniGames
        case 'LOTTO':
          return this.lottoGames
        default:
          return []
      }
    },
    masters() {
      return this.userInfo.masters || []
    },
    agents() {
      if (this.userInfo.roleName === 'OWNER') {
        return (
          this.userInfo.masters.find(
            (master) => master.id === this.selectedMasterId
          )?.children || []
        )
      } else if (this.userInfo.roleName === 'MASTER') {
        return this.userInfo.masters[0].children || []
      } else if (this.userInfo.roleName === 'AGENT') {
        return this.userInfo.masters[0].children || []
      }
      return []
    },
  },
  watch: {
    filteredGameTypes(val) {
      if (val.length > 0 && !this.selectedGameType) {
        this.selectedGameType = val[0].value
      }
    },
    selectedGameType(val) {
      if (val) {
        this.filterGame = this.gameOptions[0].id
      }
    },
    agents(val) {
      if (val.length === 1) {
        this.selectedAgentId = this.userInfo.masters[0].children[0].id
      }
    },
  },
  async created() {
    await this.fetchProviderGames()
    this.isLoading = false
    if (this.filteredGameTypes.length > 0) {
      this.selectedGameType = this.filteredGameTypes[0].value
    }
  },
  methods: {
    ...mapActions(['fetchProviderGames']),
  },
}
</script>
